<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REMITTANCES CASH FLOW</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md5>
            <v-form ref="form" class="multi-col-validation">
              <v-layout row wrap class="mx-1 mb-5">
                <v-flex xs12 md12>
                  <v-text-field v-model="month_of" class="mx-2" dense label="Month Of" readonly
                                disabled></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-select
                    v-model="category_id"
                    class="mx-2"
                    dense
                    outlined
                    label="Category"
                    :items="category_items"
                    item-value="id"
                    item-text="category"
                    @change="selected_category"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md12 v-if="!is_final_report">
                  <v-toolbar dense dark color="primary mt-3">
                    <v-toolbar-title><h4 class="font-weight-light">TRANSACTION DATA</h4>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout row wrap class="mx-1 mt-3" dense>
                    <v-flex xs12 md12>
                      <v-select
                        v-model="type_of_remittances"
                        class="mx-2"
                        dense
                        outlined
                        label="Type of Remittance"
                        :items="['Palawan','Cebuana']"
                        @change="selected_transaction"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-flex><v-flex xs12 md12>
                      <v-select
                        v-model="transaction"
                        class="mx-2"
                        dense
                        outlined
                        label="Transaction"
                        :items="['Deposit']"
                        @change="selected_transaction"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="date"
                        class="mx-2"
                        dense
                        outlined
                        label="Date"
                        type="date"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-select
                        v-model="particulars"
                        class="mx-2"
                        dense
                        outlined
                        label="Particulars"
                        :items="particulars_items"
                        :rules="rules.combobox_rule"
                        @change="selected_particulars"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md12 v-if="!this.specify_disable">
                      <v-text-field
                        v-model="specify"
                        class="mx-2"
                        dense
                        outlined
                        label="Specify"
                        :rules="rules.default_max_600_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-text-field
                        v-model="amount"
                        class="mx-2"
                        dense
                        outlined
                        label="Amount"
                        type="number"
                        min="0"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        v-if="is_can_encode"
                        @keyup.enter="save_cash_flow"
                      ></v-text-field>
                      <v-text-field
                        v-model="amount"
                        class="mx-2"
                        dense
                        outlined
                        label="Amount"
                        readonly
                        v-else
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-col cols="12" v-show="alert">
                    <v-alert color="warning" text class="mb-0">
                      <div class="d-flex align-start">
                        <v-icon color="warning">
                          {{ icons.mdiAlertOutline }}
                        </v-icon>

                        <div class="ms-3">
                          <p class="text-base font-weight-medium mb-1">
                            {{ alert_message }}
                          </p>
                        </div>
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn class="w-full" color="primary" @click="save_cash_flow"
                           v-if="!saving_data">
                      Save changes
                    </v-btn>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-else
                    ></v-progress-circular>
                  </v-col>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex xs12 md7>
            <v-data-table
              dense
              :headers="headers"
              height="600"
              :items="data_items"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                  <td>
                    <v-chip>{{ item.date }}</v-chip>
                  </td>
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td v-if="!is_final_report">
                    <v-icon class="mr-2" color="info" @click="edit_details(item)"
                            v-if="!saving_data">
                      {{ icons.mdiPencil }}
                    </v-icon>
                    <v-icon class="mr-2" color="error" @click="delete_bank_transaction(item)"
                            v-if="!saving_data">
                      {{ icons.mdiDelete }}
                    </v-icon>
                    <v-progress-circular
                      :size="50"
                      :width="5"
                      color="primary"
                      indeterminate
                      v-if="saving_data"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <v-dialog v-model="can_edit" persistent max-width="30%">
      <EditDataPalawan :key="key" :data="transaction_data" :category_id="this.category_id"
                v-on:response="on_change"></EditDataPalawan>
      <v-btn color="error" @click="can_edit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiDelete,
    mdiPencil
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import EditData from './EditDataPalawan'
  import EditDataPalawan from "@/views/input_interface/cash_flow/palawan/EditDataPalawan";

  const initialState = () => {
    return {
      saving_data2: false,
      saving_data: false,
      alert2: false,
      alert: false,
      alert_message2: '',
      alert_message: '',
      can_view_voucher: false,
      key: 0,

      headers: [
        {text: 'Dep. Amount', value: 'tin', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Action', value: 'last_name', sortable: false},
      ],
      type_of_remittances: '',
      category_id: '',
      sorted_by: '',
      month_of: '',
      category_items: [],
      data_items: [],
      month_of_id: 0,
      bank_id: 0,
      bank_balance: 0,
      bank_items: [],
      account_no: '',
      account_name: '',
      last_month_balance: '0.00',
      total_deposit: '0.00',
      total_withdrew: '0.00',
      remaining_balance: '0.00',

      amount: '',
      specify: '',
      particulars: '',
      date: '',
      cheque_no: '',
      cheque_no_data: '',
      ftv_deposit: '',
      return_fund: '',
      dep_receivables: '',
      auto_debit_ftv: '',
      auto_debit_ckv: '',
      auto_debit_cv: '',
      transaction: '',
      category_selected: '',

      is_can_encode: true,

      ftv_deposit_items: [],
      return_fund_items: [],
      dep_receivables_items: [],
      particulars_items: [],

      auto_debit_ftv_items: [],
      auto_debit_ckv_items: [],
      auto_debit_cv_items: [],

      is_auto_debit: false,

      is_dep_receivables: false,
      is_return_fund: false,
      is_ftv_deposit: false,
      is_auto_debit_ftv: false,
      is_auto_debit_ckv: false,
      is_auto_debit_cv: false,
      is_cheque: false,
      specify_disable: false,
      is_final_report: false,
      can_final_report: false,

      cheque_no_items: [],
      isLoading: false,

      request_data_id: '',
      cheque_date: '',
      payee: '',
      allocation_of_fund: '',

      cv_id: '',
      ckv_id: '',
      ftv_id: '',

      printing_voucher: false,
      can_edit: false,
      transaction_data: {},
    }
  }
  export default {
    components: {
      EditDataPalawan,
      snackBarDialog,
      EditData,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiDelete,
          mdiPencil,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('palawan_transactions', [
        'register_cash_flow_palawan',
        'delete_cash_flow_palawan',
        'get_palawan_transaction',
      ]),
      ...mapActions('cheque_book_series', ['search_cheque_no_used']),
      ...mapActions('category', ['category_to_final_report']),
      ...mapActions('bank_transaction', ['initialization_cash_flow']),
      initialize_data() {
        this.initialization_cash_flow()
          .then(response => {
            this.can_final_report = false
            this.category_items = response.data
            this.transaction = ''
            this.category_id = ''
            this.bank_id = ''
            this.bank_items = []
            this.data_items = []
            this.account_name = ''
            this.account_no = ''
            this.last_month_balance = ''
            this.total_deposit = ''
            this.total_withdrew = ''
            this.remaining_balance = ''
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('month_of_id', this.month_of)
      },
      selected_category(value) {
        var index = this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value)
        if (index != -1) {
          this.category_selected = this.category_items[index].category
          this.bank_items = this.category_items[index].bank_depository
          this.month_of = this.category_items[index].transaction_month.month_of
          this.month_of_id = this.category_items[index].month_of_id
          this.bank_id = ''
          this.data_items = []
          this.account_name = ''
          this.account_no = ''
          this.last_month_balance = ''
          this.total_deposit = ''
          this.total_withdrew = ''
          this.remaining_balance = ''
          this.transaction = ''
          this.is_final_report = this.category_items[index].is_can_new_month

        }
      },
      selected_transaction() {
        this.is_can_encode = true
        if (this.transaction === 'Withdrawal') {
          this.is_can_encode = false
        }
        this.can_view_voucher = false
        this.is_dep_receivables = false
        this.is_return_fund = false
        this.is_ftv_deposit = false
        this.is_auto_debit_ftv = false
        this.is_auto_debit_ckv = false
        this.is_auto_debit_cv = false
        this.is_cheque = false
        // this.date = ''
        // this.particulars = ''
        this.particulars_items = []
        this.specify = ''
        this.amount = 0
        this.dep_receivables = ''
        this.return_fund = ''
        this.ftv_deposit = ''
        this.auto_debit_ftv = ''
        this.auto_debit_ckv = ''
        this.auto_debit_cv = ''
        this.cheque_no_data = ''
        this.cheque_no = ''
        this.cheque_no_items = []
        this.request_data_id = ''
        this.payee = ''
        this.allocation_of_fund = ''
        this.cheque_date = ''
        this.cv_id = ''
        this.ckv_id = ''
        this.ftv_id = ''
        switch (this.category_selected) {
          case 'Damayan':
            this.particulars_items = ['DAM', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
            break
          case 'Chapel':
            this.particulars_items = ['SERV', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
            break
          case 'Coop':
            this.particulars_items = [
              'MAINTAINING BALANCE - ',
              'OTHERS - ',
              'REGULAR SAVINGS - ',
              'TIME DEPOSIT - ',
              'PAID UP CAPITAL - ',
              'LOAN PAYMENT - ',
              'RAW LOTS PAYMENT - ',
              'MEMBERSHIP FEE - ',
              'PROCESSING FEE',
              'FOUNDATION SHARE - ',
              'LOAN INSURANCE - ',
              'OPENING SAVINGS ACCOUNT - ',
              'SHARE CAPITAL - ',
              'MORTUARY INSURANCE - ',
              'INT',
              'INT. LESS WTX',
            ]
            break
          case 'Chapel':
            this.particulars_items = ['SALES', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
            break
          case 'HO':
            this.particulars_items = [
              'RET. FUND - ',
              'FTV - ',
              'OTHERS - ',
              'INT',
              'INT. LESS WTX',
              'MAINTAINING BALANCE - ',
            ]
            break
          default:
            this.particulars_items = ['INCOME - ', 'PURCHASE - ', 'INT', 'INT. LESS WTX', 'OTHERS - ', 'MAINTAINING BALANCE - ']
            break
        }

        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('category_id', this.category_id)
        data.append('type_of_remittances', this.type_of_remittances)
        this.get_palawan_transaction(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })

      },
      selected_particulars(value) {
        this.specify_disable = false
        if (
          value === 'INT' ||
          value === 'INT. LESS WTX' ||
          value === 'SERV' ||
          value === 'KGPY' ||
          value === 'DAM' ||
          value === 'PURCHASE'
        ) {
          this.specify_disable = true
        }
      },
      async save_cash_flow() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id)
          data.append('type_of_remittances', this.type_of_remittances)
          data.append('month_of_id', this.month_of_id)
          data.append('transaction', this.transaction)
          data.append('date', this.date)
          data.append('amount', this.amount)
          data.append('particulars', this.particulars + '' + this.specify)

          await this.register_cash_flow_palawan(data)
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY ENCODED',
              })
            })
            .catch(error => {
              console.log(error)
            })
          await this.selected_transaction()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async delete_bank_transaction(value) {
        this.saving_data = true
        const data = new FormData()
        data.append('id', value.id)
        await this.delete_cash_flow_palawan(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'DELETED SUCCESSFULLY',
            })
          })
          .catch(error => {
            console.log(error)
          })
        await this.selected_transaction()
        this.saving_data = false
      },
      async edit_details(value) {
        this.can_edit = true
        this.transaction_data = value
        this.key++
      },
      async on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })
        this.can_edit = false
        await this.selected_transaction()
      },
    },
  }
</script>
